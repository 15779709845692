import { NgModule }                              from '@angular/core';
import { DefaultFormatRegistry, FormatRegistry } from '@cs/common';
import { CustomerRoutingModule }                 from '@cs/customer/customer-routing.module';
import { DemoFormatRegistry } from '@cs/customer/demo-format-registry';

import { DefaultNavbarIconsFactory, SHELL_NAVBAR_ICONS_REPO } from '@cs/performance-manager';

export function CustomerNavbarIconsFactory() {
	const iconsLib = DefaultNavbarIconsFactory();
	return iconsLib;
}

/**
 * This bootstraps any angular customer specific code;
 */
@NgModule({
						declarations: [],
						imports:      [CustomerRoutingModule],
						providers:    [
							{provide: SHELL_NAVBAR_ICONS_REPO, useFactory: CustomerNavbarIconsFactory},
							{provide: FormatRegistry, useClass: DemoFormatRegistry}
						],
						exports:      [CustomerRoutingModule]
					})
export class CustomerInitModule {}
